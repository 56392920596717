<template>
  <div class="padding-top">
    <AgendaDatepicker />
    <AgendaCalendar />

    <AppointmentList @datepicker="handlerWeekDatepicker" />

    <div class="agenda-button">
      <Button icon="icon-plus" type="circle" @clicked="goCreateAppointment" />
    </div>
  </div>
</template>

<script>
import AppointmentList from '@/views/agenda/components/AppointmentList'
import AgendaDatepicker from '@/views/agenda/components/AgendaDatepicker'
import AgendaCalendar from '@/views/agenda/components/AgendaCalendar'
import { Button } from '@seliaco/red-panda'

export default {
  name: 'Agenda',
  components: {
    Button,
    AppointmentList,
    AgendaDatepicker,
    AgendaCalendar
  },
  created () {
    if (!this.$route.query.mode) {
      this.$router.replace({
        name: 'Agenda',
        query: {
          mode: 'date'
        }
      })
    }
  },
  methods: {
    goCreateAppointment () {
      this.$router.push({ name: 'AppointmentCreate' })
    },
    handlerWeekDatepicker (toRight) {
      const date = this.$luxon.DateTime.fromISO(this.$route.query.date)
      let dateResult = null

      if (toRight) {
        dateResult = date.plus({ day: 1 })
      } else {
        dateResult = date.plus({ day: -1 })
      }

      this.$router
        .replace({
          query: {
            ...this.$route.query,
            date: dateResult.toUTC().toISO()
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="sass">
.agenda-button
  position: fixed
  bottom: calc(70px + env(safe-area-inset-top))
  right: 40px
  z-index: 999
</style>
