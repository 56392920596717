<template>
  <div class="page-segment">
    <div class="page-segment-content">
      <DatePickerL
        v-model="date"
        :type="calendarType"
        :arrow-header="true"
        :lang="$store.getters.language.lang"
        :events="setEvents"
        :loading="datepickerLoader"
        @date-select="setDate(date)"
        @month-change="setAppointments"
        @arrow-header="openDatePicker()"
      />
    </div>
  </div>
</template>

<script>
import { DatePickerL, ServiceIcons } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
export default {
  name: 'AgendaDatepicker',
  components: {
    DatePickerL
  },
  data () {
    const dateTime = this.$luxon.DateTime

    return {
      date: null,
      showCalendar: false,
      mode: this.$route.query.mode,
      dateTime
    }
  },
  mounted () {
    if (this.$route.query.date) {
      this.setAppointments()
    } else {
      this.setDate()
    }
  },
  methods: {
    setDate (date = new Date()) {
      this.date = date
      const month = new Date(this.date).getMonth()

      if (month !== this.currentMonth) {
        this.setAppointments()
      }

      this.$router
        .replace({
          query: {
            ...this.$route.query,
            date: this.dateTime.fromJSDate(this.date).toUTC().toISO()
          }
        })
        .catch(() => {})
    },
    openDatePicker () {
      this.$store.commit('agenda/SET_OPEN_MODAL', { openModal: true })
      this.$globalEvent.$emit('main-content/title-pressed', true)
    },
    async setAppointments ($event = null) {
      if (this.openModal) {
        return
      }

      let date = this.date

      if ($event?.month) {
        date = this.dateTime
          .fromJSDate(date)
          .set({ month: $event.month, year: $event.year })
          .toJSDate()
      }

      const dateStart = this.dateTime
        .fromJSDate(date)
        .startOf('month')
        .toUTC()
        .toISO()
      const dateEnd = this.dateTime
        .fromJSDate(date)
        .endOf('month')
        .toUTC()
        .toISO()

      await this.$store.dispatch('agenda/list', {
        dateStart,
        dateEnd
      })
    }
  },
  computed: {
    ...mapGetters({
      appointmentsRaw: 'agenda/appointmentsRaw',
      timeZone: 'userTimezone',
      loading: 'agenda/loading',
      openModal: 'agenda/openModal',
      currentMonth: 'agenda/currentMonth'
    }),
    datepickerLoader () {
      if (this.$route.query.mode === 'month') {
        return this.loading
      }

      return false
    },
    calendarType () {
      return this.$route.query.mode === 'month' ? 'Normal' : 'Carrousel'
    },
    setEvents () {
      let events = []

      if (this.appointmentsRaw) {
        events = this.appointmentsRaw.map((item) => {
          return {
            date: item.starts_at,
            color: ServiceIcons[item.service.service_type.icon_name].background
          }
        })
      }

      return events
    }
  },
  watch: {
    $route: {
      handler (to) {
        if (to.query.date) {
          const date = this.dateTime.fromISO(to.query.date)
          const dateTz = date.setZone(this.timeZone)
          this.setDate(dateTz.toJSDate())
          if (to.query.mode === 'date') {
            this.mode = to.query.mode
          }
        }

        if (to.query.mode === 'month' && to.query.mode !== this.mode) {
          this.mode = to.query.mode
          this.setAppointments()
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
