export const Event = {
  CLICK_START_APPOINTMENT: 'Click_start_appointment',
  ACQUISITION_LOGIN: 'Acquisition_Login',
  ACQUISITION_LOGIN_FAILED: 'Acquisition_Login_Failed',
  ACQUISITION_LOGOUT: 'Acquisition_Logout',
  SCHEDULE_APPOINTMENT: 'Schedule_Appointment',
  PROFILE_SCHEDULE_APPOINTMENT: 'Profile_Schedule_Appointment',
  CLINICAL_EPISODE_CREATE: 'Clinical_Episode_Initiated',
  CLINICAL_EPISODE_OPEN: 'Clinical_Episode_Opened',
  CLINICAL_EPISODE_SAVE: 'Clinical_Episode_Saved',
  CLINICAL_EPISODE_DELETE: 'Clinical_Episode_Deleted',
  CONSENT_SEND: 'Consent_Sent',
  IN_APP_MESSAGE_VIEWED: 'In App Message Viewed',
  IN_APP_MESSAGE_DISMISSED: 'In App Message Dismissed',
  IN_APP_MESSAGE_CLICKED: 'In App Message Clicked'
}
