<template>
  <div class="page-segment" v-swipe="handlerSwipe">
    <div class="page-segment-content">
      <!-- loader -->
      <template v-if="loading">
        <div v-for="index in 2" :key="index">
          <div class="margin-bottom">
            <Skeleton width="100px" height="30px" borderRadius="8px" />
          </div>
          <div class="list-skeleton margin-bottom">
            <div>
              <Skeleton
                class="margin-bottom-zero"
                width="18px"
                height="12px"
                borderRadius="8px"
              />
              <Skeleton width="24px" height="12px" borderRadius="8px" />
            </div>
            <div style="width: 100%">
              <Skeleton
                class="margin-bottom"
                width="100%"
                height="107px"
                borderRadius="16px"
              />
            </div>
          </div>
        </div>
      </template>

      <!-- appointments -->
      <template v-else-if="Object.keys(appointments).length != 0">
        <div
          class="appointment-list"
          v-for="month in Object.keys(appointments)"
          :key="month"
        >
          <!-- month -->
          <div class="appointment-list-header">
            <div class="appointment-list-header_title">
              <div class="heading-small" v-text="title" />
              <Chip
                :text="chipText"
                classText="small text-purple padding-x-xs"
                size="small"
                color="purple"
              />
            </div>

            <div class="appointment-list-header_actions">
              <div
                class="icon-arrow-big-outline-left icon-display text-gray-50 cursor-pointer"
                @click="$emit('datepicker', false)"
              />
              <div
                class="icon-arrow-big-outline-right icon-display text-gray-50 cursor-pointer"
                @click="$emit('datepicker', true)"
              />
            </div>
          </div>

          <div
            class="card-container"
            v-for="(day, index) in Object.keys(appointments[month])"
            :key="index"
          >
            <!-- day -->
            <div class="date">
              <div
                class="content-title"
                v-text="subtitle(appointments[month][day]).general"
              />
              <div
                class="body-small"
                v-text="subtitle(appointments[month][day]).content"
              />
            </div>

            <!-- appointment -->
            <div class="appointment-card">
              <SpecialistsAppointmentCard
                v-for="(appointment, index) in appointments[month][day]"
                :key="index"
                v-selectable-container="{
                  callback: () => goToDetail(appointment.id),
                  elementLevel: 2
                }"
                :show-arrow="true"
                :appointment="appointment"
                @changed="getAppointments"
              />
            </div>
          </div>
        </div>
      </template>

      <div v-else>
        <!-- month -->
        <div class="appointment-list-header">
          <div class="appointment-list-header_title">
            <div class="heading-small" v-text="title" />
            <Chip
              :text="chipText"
              classText="small text-purple padding-x-xs"
              size="small"
              color="purple"
            />
          </div>

          <div class="appointment-list-header_actions">
            <div
              class="icon-arrow-big-outline-left icon-display text-gray-50 cursor-pointer"
              @click="$emit('datepicker', false)"
            />
            <div
              class="icon-arrow-big-outline-right icon-display text-gray-50 cursor-pointer"
              @click="$emit('datepicker', true)"
            />
          </div>
        </div>

        <DataZeroSimple :text="datazero.text" :image="datazero.image" />
      </div>
    </div>
  </div>
</template>

<script>
import SpecialistsAppointmentCard from '@/components/cards/SpecialistsAppointmentCard'

import {
  NoAppointmentsAlt,
  selectableContainer,
  Skeleton,
  DataZeroSimple,
  Chip,
  swipe
} from '@seliaco/red-panda'

import AppointmentRedirectMixin from '@/mixins/appointment-redirect-mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'AppointmentList',
  directives: { selectableContainer, Swipe: swipe },
  mixins: [AppointmentRedirectMixin],
  components: {
    DataZeroSimple,
    Skeleton,
    SpecialistsAppointmentCard,
    Chip
  },
  data () {
    return {
      appointments: {},
      appointmentByDay: [],
      datazero: {
        image: NoAppointmentsAlt,
        text: this.$translations['data-zero'].appointments
      }
    }
  },
  mounted () {
    this.$store.watch(
      () => this.appointmentsRaw,
      () => {
        this.getAppointments()
      }
    )
  },
  methods: {
    getAppointments () {
      const date = this.$moment(this.$route.query.date) || this.$moment()
      const appointments = this.appointmentsRaw
        .filter((item) => {
          // TODO: only day
          const iFormat = this.$moment(item.starts_at).format('YYYY-MM-DD')
          const dateFormat = this.$moment(date).format('YYYY-MM-DD')

          return this.$moment(iFormat).isSame(dateFormat)
        })
        .map((appointment) => {
          appointment.specialist_service = {
            ...appointment.service,
            ...appointment.service.service_type
          }
          return appointment
        })

      this.appointmentByDay = [...appointments]

      const group = {}

      for (let i = 0; i < appointments.length; i++) {
        const item = appointments[i]
        const date = this.$moment(item.starts_at).tz(this.timeZone)

        const day = date.clone().format('hh:mm').toString()

        // if (this.$route.query.mode === 'month') {
        //   day = date.clone().format('YYYY-MM-DD').toString()
        // }
        const month = date.clone().format('YYYY-MM-DD').toString()

        // create nested month
        if (group[month] === undefined) {
          group[month] = {}
        }

        // create nested day
        if (group[month][day] === undefined) {
          group[month][day] = []
        }

        group[month][day].push(item)
      }

      this.appointments = { ...group }
    },
    handlerSwipe (direction) {
      if (direction === 'right') {
        this.$emit('datepicker', false)
      }

      if (direction === 'left') {
        this.$emit('datepicker', true)
      }
    }
  },
  computed: {
    ...mapGetters({
      timeZone: 'userTimezone',
      appointmentsRaw: 'agenda/appointmentsRaw',
      loading: 'agenda/loading'
    }),
    title () {
      const momentTz = this.$moment(this.$route.query.date).tz(this.timeZone)
      const day = momentTz.format('DD')
      const month = momentTz.format('MMMM')
      const year = momentTz.format('YYYY')
      const monthUpperCase = month.charAt(0).toUpperCase() + month.slice(1)
      // if (this.$route.query.mode === 'month') {
      //   return `${monthUpperCase} ${year}`
      // }
      return `${day} ${monthUpperCase} ${year}`
    },
    subtitle () {
      return (day) => {
        const moment = this.$moment(day[0].starts_at).tz(this.timeZone)

        // if (this.$route.query.mode === 'month') {
        //   return {
        //     general: moment.format('DD'),
        //     content: moment.format('ddd')
        //   }
        // }

        return {
          general: moment.format('hh:mm'),
          content: moment.format('a').toUpperCase()
        }
      }
    },
    chipText () {
      const length = this.appointmentByDay.length

      if (length === 0) {
        return `0 ${this.$translations.agenda.appointments}`
      }

      if (length > 1) {
        return `${length} ${this.$translations.agenda.appointments}`
      }

      return `${length} ${this.$translations.agenda.appointment}`
    }
  },
  watch: {
    $route: {
      handler (to) {
        if (to.query.date) {
          this.getAppointments()
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.list-skeleton
  display: flex
  gap: 16px

.appointment-list-header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 16px
  &_title,
  &_actions
    display: flex
    gap: 8px

.card-container
  display: flex
  gap: 16px
  width: 100%
  margin-bottom: 16px
  .appointment-card
    display: flex
    gap: 8px
    flex-direction: column
    width: 100%
  .date
    min-width: 30px
</style>
