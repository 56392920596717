export const TransformUser = (user) => {
  if (!user) {
    return
  }

  return {
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    gender: user.gender,
    phone: `${user.dial_country_code}${user.phone}`,
    user_id: user.id,
    id: user.id,
    birthday: user.birthday
  }
}
