export default {
  methods: {
    goToDetail (id = this.appointment.id) {
      this.$router.push({
        name: 'AppointmentDetail',
        params: {
          id
        },
        query: {
          back: this.$route.fullPath
        }
      })
    }
  }
}
