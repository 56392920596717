import { Segment } from '@seliaco/red-panda'
import { Event } from '@/constants/events.constants'
import { TransformUser } from '@/filters/user-events-filter'

export const Appointment = {
  /**
   * Specialist enters clicks on the start call button
   */
  ClickStart: (appointment) => {
    const specialist = {
      firstSpecialist: appointment.specialist?.user.first_name,
      lastSpecialist: appointment.specialist?.user.last_name,
      localization: appointment.specialist?.user.localization.timezone,
      idSpecialist: appointment.specialist?.id
    }
    Segment.track(Event.CLICK_START_APPOINTMENT, {
      ...appointment.patient,
      ...appointment.service,
      ...appointment.specialist_service,
      ...specialist
    })
  }
}

export const PatientDetail = {
  /**
   * User clicks on schedule on patient profile
   */
  Profile_Schedule_Appointment: (body) => {
    Segment.track(Event.PROFILE_SCHEDULE_APPOINTMENT, {
      origin: 'patien detail',
      patient_id: body.patient_id
    })
  },
  /**
   * User clicks on send consent
   */
  consentSend: (body) => {
    Segment.track(Event.CONSENT_SEND, {
      origin: body.origin ? 'Detalle cita' : 'Perfil usuario',
      user_id: body.patient_id,
      specialist_id: body.specialist_id,
      status: body.status
    })
  }
}

export const ClinicalEpisode = {
  /**
   * User clicks on create clinical episode
   */
  create: (body) => {
    Segment.track(Event.CLINICAL_EPISODE_CREATE, {
      origin: body.origin ? 'Videollamada' : 'Detalle cita',
      user_id: body.patient_id,
      specialist_id: body.specialist_id
    })
  },
  /**
   * User clicks on open clinical episode
   */
  open: (body) => {
    Segment.track(Event.CLINICAL_EPISODE_OPEN, {
      origin: body.origin ? 'Videollamada' : 'Detalle cita',
      user_id: body.patient_id,
      specialist_id: body.specialist_id,
      episode_id: body.episode_id,
      status: body.status
    })
  },
  /**
   * User clicks on save clinical episode
   */
  save: (body) => {
    Segment.track(Event.CLINICAL_EPISODE_SAVE, {
      origin: body.origin ? 'Videollamada' : 'Detalle cita',
      user_id: body.patient_id,
      specialist_id: body.specialist_id,
      episode_id: body.episode_id,
      status: body.status
    })
  },
  /**
   * User clicks on delete clinical episode
   */
  delete: (body) => {
    Segment.track(Event.CLINICAL_EPISODE_DELETE, {
      origin: body.origin ? 'Videollamada' : 'Detalle cita',
      user_id: body.patient_id,
      specialist_id: body.specialist_id,
      episode_id: body.episode_id
    })
  }
}

export const Acquisition = {
  /**
   * Event sent when a user's account information is successfully authenticated
   */
  login: ({ user }) => {
    Segment.track(Event.ACQUISITION_LOGIN, {
      ...TransformUser(user)
    })
    Segment.identifyAppUser({ ...TransformUser(user) }, {})
  },

  /**
   * Event sent when account information is incorrect
   */
  loginFailed: ({ error, email }) => {
    Segment.track(Event.ACQUISITION_LOGIN_FAILED, {
      error_type: error,
      email
    })
  },

  /**
   * Event that is sent when a user logs out
   */
  logout: (user) => {
    Segment.track(Event.ACQUISITION_LOGOUT, {
      ...TransformUser(user)
    })
    Segment.reset()
  }
}

export const NotificationEvent = {
  InAppMessageViewed: (body) => {
    Segment.track(Event.IN_APP_MESSAGE_VIEWED, body)
  },
  InAppMessageDismissed: (body) => {
    Segment.track(Event.IN_APP_MESSAGE_DISMISSED, body)
  },
  InAppMessageClicked: (body) => {
    Segment.track(Event.IN_APP_MESSAGE_CLICKED, body)
  }
}
