<template>
  <div>
    <AppointmentCard
      :title="title"
      :showImage="false"
      :first-line="firstLine"
      :middle-line="middleLine"
      :bottom-line="bottomLine"
      :button-action="action"
      :active="active"
      :service-icon="serviceIcon"
      :arrow-action="showArrow"
      :custom-background="customBackground"
    />

    <!-- conclude modal -->
    <CenteredDialog
      v-if="showConcludeModal"
      :title="$translations.appointment.action['conclude']"
      :options="concludeOptions"
      icon="icon-appointment-on"
      @close="showConcludeModal = false"
    />
  </div>
</template>

<script>
import AppointmentDetailMixin from '@/mixins/appointment-detail-mixin'
import { Appointment } from '@/types/events'
import {
  Appointments,
  selectableContainer,
  AppointmentCard,
  CenteredDialog
} from '@seliaco/red-panda'

export default {
  name: 'SpecialistsAppointmentCard',
  mixins: [AppointmentDetailMixin],
  props: {
    appointment: {
      id: String,
      starts_at: String,
      virtual: Boolean,
      status: String,
      price: Number,
      specialist: {
        id: String,
        user: {
          first_name: String,
          last_name: String
        }
      },
      patient: {
        first_name: String,
        last_name: String
      },
      specialist_service: {
        price: Number,
        type: String,
        name: String,
        icon_name: String,
        duration: Number
      },
      is_first_session: Boolean
    },
    detailIsEnabled: {
      type: Boolean,
      default: true
    },
    showArrow: Boolean
  },
  components: {
    AppointmentCard,
    CenteredDialog
  },
  data () {
    return {
      showConcludeModal: false,
      concludeOptions: [
        {
          text: this.$translations.appointment.action.concluded,
          style: 'FILLED',
          action: () => {
            this.changeAppointmentStatus('COMPLETED')
            this.showConcludeModal = false
            this.navigateAppointmentDetail()
          }
        },
        {
          text: this.$translations.appointment.action['no-attended'],
          style: 'FILLED',
          severity: 'DANGER',
          action: () => {
            this.changeAppointmentStatus('NO_ATTEND_USER')
            this.showConcludeModal = false
            this.navigateAppointmentDetail()
          }
        },
        {
          text: this.$translations.general.cancel,
          style: 'OUTLINED',
          action: () => {
            this.showConcludeModal = false
          }
        }
      ]
    }
  },
  methods: {
    onConcludeAppointment () {
      this.showConcludeModal = true
    },
    changeAppointmentStatus (status) {
      Appointments.update(this.appointment.id, {
        status
      })
        .then(() => {
          this.$toast({
            text: this.$translations.success.default,
            severity: 'success'
          })

          this.$emit('changed')
        })
        .catch(() => {
          this.$toast({
            text: this.$translations.error.default,
            severity: 'error'
          })
        })
    },
    navigateAppointmentDetail () {
      this.$router.push({
        name: 'AppointmentDetail',
        params: { id: this.appointment.id }
      })
    }
  },
  computed: {
    isItOver () {
      return this.itsOver()
    },
    title () {
      const patient = this.appointment.patient

      return `${patient?.first_name} ${
        patient?.last_name ? patient?.last_name : ''
      }`
    },
    firstLine () {
      return this.appointment.specialist_service?.name
    },
    middleLine () {
      const timeZone = this.$store.getters.userTimezone
      const startsAt = this.$moment(this.appointment.starts_at).tz(timeZone)

      if (!this.alreadyStarted() && this.isAboutToStart()) {
        const fromNow = startsAt.fromNow()

        return `${this.$translations.general.starts} <span style="color: var(--purple)">${fromNow}</span>`
      }

      const date = this.$moment(this.appointment.starts_at)
        .tz(timeZone)
        .format('D MMMM, ddd • h:mm a')

      return this.appointment.specialist_service
        ? `${date} (${this.appointment.specialist_service.duration}min)`
        : `${date}`
    },
    bottomLine () {
      const status = this.$translations.status[this.status] || ''
      const virtual =
        this.$translations.appointments.virtual[this.appointment.virtual]
      const redStatus = [
        'NO_ATTEND_USER',
        'NO_ATTEND_SPECIALIST',
        'NON_COMPLETED',
        'NO_ATTEND_USER_WITH_RESCHEDULE',
        'CANCELED'
      ]

      const alertStatus = ['PENDING', 'PAYMENT_DECLINED']

      const checkStatus = ['COMPLETED', 'CONFIRM']

      if (redStatus.includes(this.appointment.status)) {
        return `${virtual} • <span style="color: var(--red);">${status}</span>`
      }

      if (alertStatus.includes(this.appointment.status)) {
        return `
          <div style="display: flex; gap: 2px;">
            <span>${virtual} •</span>
            <span class="icon-warning-circle-on" style="color: var(--yellow); font-size: var(--base)"></span>
            <span>${status}</span>
          </div>
        `
      }

      if (checkStatus.includes(this.appointment.status)) {
        return `
          <div style="display: flex; gap: 2px;">
            <span>${virtual} •</span>
            <span class="icon-checkmark-circle-on" style="color: var(--green); font-size: var(--base)"></span>
            <span>${status}</span>
          </div>
        `
      }

      return this.status ? `${virtual} • ${status}` : `${virtual}`
    },
    action () {
      switch (this.appointment.status) {
        case 'PENDING':
        case 'PAYMENT_DECLINED':
          return null

        case 'CONFIRM':
        case 'IN_WAITING_ROOM':
        case 'IN_PROGRESS_WAITING':
          if (this.active && this.appointment.virtual) {
            return {
              text: this.$translations.appointment.attend,
              callback: () => {
                Appointment.ClickStart(this.appointment)
                this.$router.push({
                  name: 'Lobby',
                  params: {
                    id: this.appointment.id
                  }
                })
              }
            }
          }

          return null

        case 'IN_PROGRESS':
          if (this.appointment.virtual) {
            return {
              text: this.$translations.appointment.attend,
              callback: () => {
                this.$router.push({
                  name: 'Lobby',
                  params: {
                    id: this.appointment.id
                  }
                })
              }
            }
          } else {
            return {
              text: this.$translations.appointment.action.conclude,
              callback: () => {
                this.onConcludeAppointment()
              }
            }
          }

        case 'NO_ATTEND_USER':
          return {
            text: this.$translations.buttons['new-request'],
            callback: () => {
              this.$router.push({
                name: 'AppointmentCreate',
                params: {
                  id: this.appointment.specialist.id
                },
                query: {
                  patient_id: this.appointment.patient.id,
                  origin_appointment_id: this.appointment.id
                }
              })
            }
          }

        case 'NON_COMPLETED':
        case 'NO_ATTEND_SPECIALIST':
        case 'NO_ATTEND_USER_WITH_RESCHEDULE':
          return {
            text: this.$translations.appointment['action-sheet'].reschedule,
            callback: () => {
              this.$globalEvent.$emit('modal/reschedule', {
                showDialog: { reschedule: { open: true, close: false } },
                appointment: this.appointment
              })
            }
          }
      }

      return null
    },
    active () {
      const preAllowedStatus = [
        'IN_WAITING_ROOM',
        'IN_PROGRESS_WAITING',
        'IN_PROGRESS'
      ]

      if (preAllowedStatus.includes(this.appointment.status)) {
        return true
      }

      if (!this.isAboutToStart()) {
        return null
      }

      const allowedStatus = ['CONFIRM']
      return allowedStatus.includes(this.appointment.status)
    },
    serviceIcon () {
      return this.appointment.specialist_service?.icon_name
    },
    customBackground () {
      return this.appointment.is_first_session ? 'var(--blue)' : ''
    }
  },
  directives: { selectableContainer }
}
</script>
