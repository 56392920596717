<template>
  <div class="agenda-calendar" v-if="showCalendar">
    <div class="agenda-calendar-overlay" @click="handlerClose" />
    <div class="agenda-calendar-content">
      <DatePickerL
        v-model="date"
        :lang="$store.getters.language.lang"
        :has-divisor="true"
        @date-select="setDate(date)"
        @month-change="setAppointments"
        :only-month="calendarDateFormat"
      />
    </div>
  </div>
</template>

<script>
import { DatePickerL } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  name: 'AgendaCalendar',
  components: {
    DatePickerL
  },
  data () {
    const dateTime = this.$luxon.DateTime

    return {
      date: null,
      showCalendar: false,
      dateTime
    }
  },
  mounted () {
    this.date = this.dateTime.fromISO(this.$route.query.date).toJSDate()

    if (this.date) {
      this.setAppointments()
    } else {
      this.setDate()
    }

    this.$globalEvent.$on('main-content/title-pressed', () => {
      this.showCalendar = true
    })
  },
  methods: {
    setDate (date = new Date()) {
      this.date = date
      this.$router
        .replace({
          query: {
            ...this.$route.query,
            date: this.dateTime.fromJSDate(date).toUTC().toISO()
          }
        })
        .catch(() => {})
    },
    async setAppointments ($event = null) {
      let date = this.date

      if ($event?.month) {
        date = this.dateTime
          .fromJSDate(date)
          .set({ month: $event.month, year: $event.year })
          .toJSDate()
      }

      const dateStart = this.dateTime
        .fromJSDate(date)
        .startOf('month')
        .toUTC()
        .toISO()
      const dateEnd = this.dateTime
        .fromJSDate(date)
        .endOf('month')
        .toUTC()
        .toISO()

      await this.$store.dispatch('agenda/list', {
        dateStart,
        dateEnd
      })
    },
    handlerClose () {
      this.showCalendar = false
      this.$store.commit('agenda/SET_OPEN_MODAL', { openModal: false })
    }
  },
  computed: {
    ...mapGetters({
      appointments: 'agenda/appointments',
      currentDate: 'agenda/currentDate',
      timeZone: 'userTimezone'
    }),
    calendarDateFormat () {
      return this.$route.query.mode === 'month'
    }
  },
  watch: {
    $route (to) {
      if (to.query.date) {
        const date = this.dateTime.fromISO(to.query.date)
        const dateTz = date.setZone(this.timeZone)
        this.date = dateTz.toJSDate()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.agenda-calendar
  position: fixed
  height: 100vh
  width: 100vw
  top: 0
  right: 0
  left: 0
  z-index: 999999

  &-overlay
    background-color: rgba(0, 0, 0, .4)
    position: fixed
    height: 100vh
    width: 100vw
    top: 0
    right: 0
    left: 0

  &-content
    position: absolute
    bottom: 50%
    right: 50%
    transform: translate(50%, 50%)
    max-width: 480px
    min-width: 343px
    border-radius: 8px
    height: min-content
    background: var(--white)
    padding: 16px

@media (min-width: 480px)
  .agenda-calendar
    &-content
      width: 100%
</style>
